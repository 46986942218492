import { Button } from '@rio-cloud/rio-uikit'

const ExceptionsPage = () => {
  return (
    <main
      className="flex h-full flex-col bg-white px-50 pt-50"
      data-testid="exceptions-page-content"
    >
      {/* TODO: headline, button & dialog */}
      <div>
        <h4 className="font-bold">ODD exception requests</h4>
        <Button bsStyle={Button.PRIMARY} className="mt-25">
          <span className="rioglyph rioglyph-plus" /> New request
        </Button>
      </div>
      <div>{/* TODO: exception requests table */}</div>
    </main>
  )
}

export default ExceptionsPage
