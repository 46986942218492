import MapSettingsItem from '@rio-cloud/rio-uikit/MapSettingsItem'

interface MapLayerHubItemProps {
  isActive: boolean
  onToggle: () => void
}
export const MapLayerHubItem = ({ isActive, onToggle }: MapLayerHubItemProps) => {
  const icon = (
    <span
      data-active={isActive}
      className="rioglyph rioglyph-factory flex size-40 items-center justify-center text-[200%] data-[active=false]:text-gray data-[active=true]:text-primary"
    />
  )

  return (
    <MapSettingsItem
      label="Hubs"
      className="text-14"
      onClick={onToggle}
      isActive={isActive}
      inactiveIcon={icon}
    />
  )
}
