import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { HereReverseGeocodeResponseSchema } from 'helper/zod'
import { buildReverseGeocodeParams } from './utils'
import { Position } from '@rio-cloud/rio-uikit/mapTypes'

const HERE_REVERSE_GEOCODE_BASE_URL = import.meta.env.VITE_HERE_REVERSE_GEOCODE_BASE_URL
const MAP_SERVICE_API_KEY = import.meta.env.VITE_MAP_SERVICE_API_KEY

export const reverseGeocodeApi = createApi({
  reducerPath: 'reverseGeocode',
  baseQuery: fetchBaseQuery({
    baseUrl: HERE_REVERSE_GEOCODE_BASE_URL
  }),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAddress: builder.query<string | undefined, Position | undefined>({
      query: (position) => ({
        url: '/revgeocode',
        params: {
          at: buildReverseGeocodeParams(position),
          lang: 'en-US', // TODO: change this once we have i18n implemented
          apiKey: MAP_SERVICE_API_KEY
        },
        headers: {
          accept: 'application/json'
        }
      }),
      transformResponse: (response, position) => {
        if (!position) {
          return
        }
        const reverseGeocodeResponse = HereReverseGeocodeResponseSchema().parse(response)
        return reverseGeocodeResponse.items?.[0]?.title
      }
    })
  })
})

export const { useGetAddressQuery, useLazyGetAddressQuery } = reverseGeocodeApi
