import EmptyLogo from './assets/pvl_empty_state.svg?react'

const EmptyAlertList = () => {
  return (
    <div
      data-testid="empty-alert-list"
      className="relative flex h-[90%] w-full items-center justify-center"
    >
      <div className="flex size-[200px] items-center justify-center rounded-full bg-primary">
        <EmptyLogo />
      </div>
      <div className="absolute h-[200px] w-full">
        <div className="absolute inset-x-0 top-[-130px] flex flex-col items-center justify-center">
          <h2 className="mb-10 text-center font-bold text-primary">ALL GOOD!</h2>
          <span className="text-center text-h4 text-primary">
            Incoming cards will appear
            <br />
            here automatically
          </span>
        </div>
        <div className="absolute -bottom-50 flex w-full items-center justify-center">
          <span className="text-center text-h4 text-primary">Go grab a coffee</span>
        </div>
      </div>
    </div>
  )
}

export default EmptyAlertList
