import { BoundingBox, Position } from '@rio-cloud/rio-uikit/mapTypes'
import { MissionStateValue, PositionInput, Severity, Vehicle } from 'API'
import { AlertWithVIN } from 'helper/types'
import { isEmpty } from 'remeda'

export const DEFAULT_POSITION: Readonly<PositionInput> = {
  latitude: 48.1351,
  longitude: 11.582,
  altitude: 123.7200275750379,
  heading: 0.0
}

// TODO: use default position, handle optional lat lon value from API type
export const getInitialMapPosition = (): Position => ({
  lat: 48.1351,
  lng: 11.582
})

export const DEFAULT_ZOOM = 10
export const ZOOMED_VEHICLE = 15

export const getMapPosition = (vehicle?: Vehicle) =>
  vehicle?.position ? vehicle.position : DEFAULT_POSITION

export const getMapZoom = (hasVehicle: boolean = false) =>
  hasVehicle ? ZOOMED_VEHICLE : DEFAULT_ZOOM

export const calculateBoundingBox = (positions: Position[]): BoundingBox => {
  let pos = positions
  if (isEmpty(positions)) {
    pos = [getInitialMapPosition()]
  }
  const allLats = pos.map(({ lat }) => lat)
  const allLngs = pos.map(({ lng }) => lng)

  const top = Math.max(...allLats)
  const bottom = Math.min(...allLats)
  const right = Math.max(...allLngs)
  const left = Math.min(...allLngs)

  const paddingY = (top - bottom) / 2
  const paddingX = (right - left) / 2
  return {
    top: top + paddingY,
    left: left - paddingX,
    right: right + paddingX * 1.5,
    bottom: bottom - paddingY
  }
}

export const getSeverityLevel = (vin: string, alerts?: AlertWithVIN[]): Severity | undefined => {
  return alerts ? alerts?.find((_) => _.vin === vin)?.severity : undefined
}

type RouteColorAssigned = {
  color: '#9AD0DC'
  borderColor: '#328092'
}
type RouteColorActive = {
  color: '#328092'
  borderColor: '#255F6C'
}

type RouteColorPaused = {
  color: '#A7AFBB'
  borderColor: '#4C5667'
}

type RouteColorCompleted = {
  color: '#71B564'
  borderColor: '#5C8D52'
}

type RouteColorFailed = {
  color: '#D90000'
  borderColor: '#B20000'
}

type RouteColorCanceled = {
  color: '#D90000'
  borderColor: '#B20000'
}

type RouteColor =
  | RouteColorAssigned
  | RouteColorActive
  | RouteColorPaused
  | RouteColorCompleted
  | RouteColorCanceled
  | RouteColorFailed

/**
 * Returns the route color based on the mission state.
 *
 * @param {MissionStateValue} missionState - The current state of the mission.
 * @return {RouteColor} The color associated with the mission state.
 */
export const getRouteColor = (missionState: MissionStateValue | undefined): RouteColor => {
  const redRoute: RouteColorFailed = { color: '#D90000', borderColor: '#B20000' }
  const colorMapping: Record<MissionStateValue, RouteColor> = {
    [MissionStateValue.ASSIGNED]: { color: '#9AD0DC', borderColor: '#328092' },
    [MissionStateValue.ACTIVE]: { color: '#328092', borderColor: '#255F6C' },
    [MissionStateValue.RESET]: { color: '#328092', borderColor: '#255F6C' },
    [MissionStateValue.PAUSED]: { color: '#A7AFBB', borderColor: '#4C5667' },
    [MissionStateValue.COMPLETED]: { color: '#71B564', borderColor: '#5C8D52' },
    [MissionStateValue.CANCELED]: redRoute,
    [MissionStateValue.FAILED]: redRoute
  }

  return missionState ? colorMapping[missionState] || redRoute : redRoute
}
