import { Text, Image, Heading, View, useTheme } from '@aws-amplify/ui-react'
import tratonLogo from './assets/traton-logo.png'

export const components = {
  Header: () => {
    const { tokens } = useTheme()
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image width="400" height="auto" alt="Traton logo" src={tratonLogo} />
      </View>
    )
  },
  Footer: () => {
    const { tokens } = useTheme()
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
      </View>
    )
  },

  SignIn: {
    Header: () => {
      const { tokens } = useTheme()

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to your account
        </Heading>
      )
    },
    Footer: () => {
      return null
    }
  }
}

export const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email'
    }
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password'
    }
  }
}
