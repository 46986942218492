import Spinner from 'components/Spinner'

const AlertsListSpinner = () => {
  return (
    <div className="flex h-[90%] items-center justify-center" data-testid="alerts-list-spinner">
      <Spinner borderWidth={8} size={200} />
    </div>
  )
}

export default AlertsListSpinner
