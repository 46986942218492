import { useDispatch, useSelector } from 'react-redux'
import { setSelectedVehicle, clearSelectedVIN, getSelectedVIN } from 'store/slices/appSlice'
import { DrivingState, Severity, Vehicle } from 'API'
import { transformToRioPosition } from 'helper/position'
import EventUtils from '@rio-cloud/rio-uikit/EventUtils'
import Marker from '@rio-cloud/rio-uikit/Marker'
import SingleMapMarker, { SingleMapMarkerProps } from '@rio-cloud/rio-uikit/SingleMapMarker'
import { getVehicleIdentifier } from 'helper/vehicle'

interface VehicleMarkerProps {
  vehicle: Vehicle
  severity?: Severity
}

const VehicleMarker = ({ vehicle, severity }: VehicleMarkerProps) => {
  const dispatch = useDispatch()
  const selectedVIN = useSelector(getSelectedVIN)
  const isSelected = selectedVIN === vehicle?.vin
  const { position } = vehicle
  const vehicleIdentifier = getVehicleIdentifier(vehicle)
  const isPinging = severity !== undefined && !isSelected
  const markerColor = {
    [Severity.ERROR]: 'bg-map-marker-danger',
    [Severity.WARNING]: 'bg-map-marker-warning',
    default: 'bg-map-marker-asset'
  }[severity || 'default'] as SingleMapMarkerProps['markerColor']

  const handleClick = () => {
    if (isSelected) {
      dispatch(clearSelectedVIN())
      return
    }

    dispatch(setSelectedVehicle({ selectedVIN: vehicle?.vin, followSelectedVehicle: false }))
  }

  const eventListenerMap = {
    [EventUtils.TAP]: handleClick
  }

  if (!position) {
    return null
  }

  return (
    <Marker
      eventListenerMap={eventListenerMap}
      position={transformToRioPosition(position)}
      icon={
        <SingleMapMarker
          fixed
          bearing={vehicle.position?.heading || undefined}
          moving={vehicle.drivingState === DrivingState.DRIVING}
          name={vehicleIdentifier}
          iconNames={['truck']}
          active={isSelected}
          pinging={isPinging}
          markerColor={markerColor}
        />
      }
    />
  )
}

export default VehicleMarker
