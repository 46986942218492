import { ComponentProps, ElementRef, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import classnames from 'classnames'
import WarningIcon from 'components/assets/warning_icon.svg?react'
import ErrorIcon from 'components/assets/danger_icon.svg?react'
import { CommandInfo, Severity } from 'API'
import { assertNever } from 'helper/assertNever'
import { AlertWithRelativeTime } from './VehicleAlert'
import CommandProgressIcon from './CommandProgressIcon'

type SVGPropTypes = ComponentProps<typeof ErrorIcon>

interface VehicleAlertCardProps {
  data: AlertWithRelativeTime
  isSelected: boolean
  commandInfo?: CommandInfo
  onClick: () => void
  vehicleIdentifier: string
  className?: string
}

interface AlertIconProps extends SVGPropTypes {
  severity: Severity
}

const AlertIcon = ({ severity, ...restOfProps }: AlertIconProps) => {
  switch (severity) {
    case Severity.ERROR:
      return <ErrorIcon {...restOfProps} />

    case Severity.WARNING:
      return <WarningIcon {...restOfProps} />

    default:
      assertNever(severity, 'AlertIcon')
      break
  }
}

const defaultOffSetWidth = 350
const iconOffSet = 20

const VehicleAlertCard = ({
  data,
  vehicleIdentifier,
  isSelected,
  onClick,
  className,
  commandInfo
}: VehicleAlertCardProps) => {
  const elementRef = useRef<ElementRef<'div'>>(null)
  const { severity, description, relativeTime } = data

  const descriptionOffsetWidthClass = `w-[${(elementRef.current?.offsetWidth || defaultOffSetWidth) - iconOffSet}]`

  const severityClass = {
    ERROR: 'border-danger',
    WARNING: 'border-warning',
    default: 'border-info'
  }[severity || 'default']

  const linearGradient = {
    ERROR: 'linear-gradient(90deg, #FFF5F5 0%, #FFF 98.87%)',
    WARNING: 'linear-gradient(90deg, #FFF6F0 0%, #FFF 98.87%)',
    default: undefined
  }[severity || 'default']

  const handleClick = () => {
    onClick()
  }

  return (
    <AnimatePresence mode="popLayout">
      <motion.button
        initial={{
          y: -50,
          opacity: 0,
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: 3,
          borderLeftWidth: 3
        }}
        transition={{ ease: 'easeInOut', duration: 0.5 }}
        animate={{
          y: 0,
          opacity: 1,
          borderLeftWidth: isSelected ? 75 : 3,
          backgroundImage: isSelected ? linearGradient : undefined
        }}
        exit={{ y: 50, opacity: 0 }}
        className={classnames(
          `mb-10 flex size-full bg-white pl-10 transition-shadow hover:shadow
    ${severityClass}`,
          className
        )}
        onClick={handleClick}
      >
        <div
          ref={elementRef}
          className="relative flex size-full flex-col p-15"
          data-testid={`vehicle-alert-card-${vehicleIdentifier.toLowerCase()}`}
        >
          {!!commandInfo && (
            <div className="absolute right-0 top-0 p-15">
              <CommandProgressIcon commandInfo={commandInfo} />
            </div>
          )}
          <p className="mb-5 text-left">
            <i>{relativeTime}</i>
          </p>
          <p className="mb-0 truncate text-left font-body text-h4 font-bold">{vehicleIdentifier}</p>
          <div className="mt-5 flex items-center">
            <div className="size-20">
              <AlertIcon severity={severity} width={iconOffSet} height={iconOffSet} />
            </div>
            <p className={classnames(descriptionOffsetWidthClass, 'my-0 ml-10 truncate text-left')}>
              {description}
            </p>
          </div>
        </div>
      </motion.button>
    </AnimatePresence>
  )
}

export default VehicleAlertCard
