import classnames from 'classnames'

interface SpinnerProps {
  borderWidth: number
  size: number
}

/**
 * TODO: Either fully use tailwind classes or request big spinner from UIKit.
 * We're relying on the UIKit implementation of .spinner CSS class.
 *
 * @param {number} borderWidth Width of border in pixels
 * @param {number} size Size of the Spinner in pixels
 */
const Spinner = ({ borderWidth, size }: SpinnerProps) => {
  const borderWidthClass = `border-[${borderWidth}px]`
  const dimensions = `size-[${size}px]`
  return (
    <div data-testid="spinner" className={classnames('spinner', dimensions)}>
      <div className={borderWidthClass} />
      <div className={borderWidthClass} />
      <div className={borderWidthClass} />
      <div className={borderWidthClass} />
    </div>
  )
}

export default Spinner
