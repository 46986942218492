import { FC, PropsWithChildren, memo } from 'react'
import VehicleAlert from './VehicleAlert'
import AlertsListSpinner from './AlertsListSpinner'
import EmptyAlertList from './EmptyAlertList'
import { AlertWithVIN } from 'helper/types'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorFallback from 'components/ErrorFallback'

interface PrioritizedAlertListProps {
  errors?: AlertWithVIN[]
  warnings?: AlertWithVIN[]
  isLoading: boolean
  isEmpty: boolean
}

const VehicleAlertListContainer: FC<PropsWithChildren> = ({ children }) => {
  return <div className="w-full p-0">{children}</div>
}

const PrioritizedAlertList = ({
  errors,
  warnings,
  isLoading,
  isEmpty
}: PrioritizedAlertListProps) => {
  if (isLoading) {
    return <AlertsListSpinner />
  }

  if (!isLoading && isEmpty) {
    return <EmptyAlertList />
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <VehicleAlertListContainer>
          {errors?.map((error) => {
            return <VehicleAlert key={error.id + error.vin} alert={error} />
          })}
        </VehicleAlertListContainer>
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <VehicleAlertListContainer>
          {warnings?.map((warning) => {
            return <VehicleAlert key={warning.id + warning.vin} alert={warning} />
          })}
        </VehicleAlertListContainer>
      </ErrorBoundary>
    </>
  )
}

export default memo(PrioritizedAlertList)
