import { useDispatch } from 'react-redux'
import Button from '@rio-cloud/rio-uikit/Button'
import { useAuthenticator } from '@aws-amplify/ui-react'
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem'
import { useGetUserAttributesQuery, userApi } from '../../services/user'

import { persistor } from 'store/setup'
import { resetAppState } from 'store/slices/appSlice'

const AccountMenu = () => {
  const dispatch = useDispatch()
  const { data: userAttributes } = useGetUserAttributesQuery('User')
  const { signOut } = useAuthenticator((context) => [context.user])

  const logOut = () => {
    signOut()
    // clear the user state, reset the app state and purge persisted state in local storage
    dispatch(userApi.util.resetApiState())
    dispatch(resetAppState())
    persistor.purge()
  }

  if (!userAttributes) {
    return
  }

  return (
    <ActionBarItem hidePopoverOnClick={false}>
      <ActionBarItem.Icon>
        <span className="icon rioglyph rioglyph-user" />
      </ActionBarItem.Icon>
      <ActionBarItem.Popover>
        <ActionBarItem.List>
          <ActionBarItem.ListItem>
            <div>
              <p className="m-0 font-bold text-gray">
                {userAttributes.given_name}&nbsp;{userAttributes.family_name}
              </p>
              <p className="m-0 mt-10 text-10 text-gray">{userAttributes.email}</p>
            </div>
          </ActionBarItem.ListItem>
          <ActionBarItem.ListSeparator />
          <ActionBarItem.ListItem>
            <Button onClick={logOut} bsStyle={Button.MUTED}>
              <span className="rioglyph rioglyph-off mr-5 text-16 text-gray" />
              Logout
            </Button>
          </ActionBarItem.ListItem>
        </ActionBarItem.List>
      </ActionBarItem.Popover>
    </ActionBarItem>
  )
}

export default AccountMenu
