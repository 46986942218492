import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader'
import { ROUTES } from 'routes/types'

const AppHeader = () => {
  const navItems = [
    {
      key: 'Exceptions',
      route: (
        <NavLink to={`/${ROUTES.EXCEPTIONS}`}>
          <p className="font-display">
            <FormattedMessage id={'intl-msg:control-center.sublink.exceptions'} />
          </p>
        </NavLink>
      )
    }
  ]

  return <ApplicationHeader showHomeIcon={false} navItems={navItems} />
}

export default AppHeader
