import { CommandInfo } from 'API'
import { useMemo } from 'react'
import useMRMProcess from '../mrm/hooks/useMRMProcess'

interface CommandProgressIconProps {
  commandInfo?: CommandInfo
}

const CommandProgressIcon = ({ commandInfo }: CommandProgressIconProps) => {
  const { isMRM, isPerforming, isCompleted, isRelease } = useMRMProcess(commandInfo)

  const { label, icon } = useMemo(() => {
    const spinner = (
      <span
        data-testid="mrm-progress-icon"
        className="rioglyph rioglyph-spinner h-[16px] animate-spin text-16"
      />
    )

    const pause = (
      <span
        data-testid="mrm-progress-icon"
        className="rioglyph rioglyph-pause h-[16px] content-center text-16"
      />
    )

    switch (true) {
      case isCompleted && isMRM:
        return {
          label: 'MRC',
          icon: pause
        }
      case isPerforming && isMRM:
        return {
          label: 'MRM',
          icon: spinner
        }
      case isPerforming && isRelease:
        return {
          label: 'Releasing',
          icon: spinner
        }
      default:
        return { label: null, icon: null }
    }
  }, [isPerforming, isCompleted, isMRM, isRelease])

  if (!label || !icon) {
    return null
  }

  return (
    <div className="flex gap-5 rounded bg-danger/5 p-4 align-middle text-gray-dark">
      <p className="text-12">{label}</p>
      {icon}
    </div>
  )
}

export default CommandProgressIcon
