import { Severity } from 'API'
import { AlertWithVIN, ExtendedVehicle } from 'helper/types'
import { pipe, sortBy, prop, mapValues } from 'remeda'
import { GenericRecord } from 'services/utils'

export const prioritizeVehicleAlerts = (vehicles?: GenericRecord<ExtendedVehicle>) => {
  if (!vehicles) {
    return
  }
  return pipe(
    Object.values(vehicles),
    (_) =>
      _.reduce(
        (pre: Record<string, AlertWithVIN[]>, cur) => ({ ...pre, [cur.vin]: cur.alerts }),
        {}
      ),
    mapValues(sortBy([prop('severity'), 'asc'], [prop('sentAt'), 'asc'])),
    Object.values,
    (sortedAlerts) => sortedAlerts.map((_) => _[0]),
    (_: AlertWithVIN[]) => ({
      errors: sortBy(
        _.filter((alert) => alert?.severity === Severity.ERROR),
        [prop('sentAt'), 'asc']
      ),
      warnings: sortBy(
        _.filter((alert) => alert?.severity === Severity.WARNING),
        [prop('sentAt'), 'asc']
      )
    })
  )
}

export const getLastCharacters = (value: string, numberOfCharacters: number) =>
  value.slice(-numberOfCharacters)
