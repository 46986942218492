/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const triggerVehicleCommand = /* GraphQL */ `mutation TriggerVehicleCommand($input: VehicleCommandInput!) {
  triggerVehicleCommand(input: $input) {
    invocationId
    command
    vin
    isSuccess
    receivedByVehicleAt
    sentToVehicleAt
    errorMessage
  }
}
` as GeneratedMutation<
  APITypes.TriggerVehicleCommandMutationVariables,
  APITypes.TriggerVehicleCommandMutation
>;
export const assignMission = /* GraphQL */ `mutation AssignMission($input: AssignMissionInput!) {
  assignMission(input: $input) {
    id
    name
    description
    createdAt
    updatedAt
    route {
      predefinedRouteId
      externalRouteId
      launchHubId
      launchHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      landingHubId
      landingHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      totalDistanceInMeters
      durationInSeconds
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
    }
    vin
    actualStartTime
    actualArrivalTime
    plannedArrivalTime
    state {
      value
      updatedAt
    }
    progressDetails {
      updatedAt
      eta
      traveledDistanceInMeters
      remainingDistanceInMeters
    }
    events {
      type
      createdAt
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.AssignMissionMutationVariables,
  APITypes.AssignMissionMutation
>;
export const updatedMission = /* GraphQL */ `mutation UpdatedMission($input: UpdatedMissionInput!) {
  updatedMission(input: $input) {
    id
    name
    description
    createdAt
    updatedAt
    route {
      predefinedRouteId
      externalRouteId
      launchHubId
      launchHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      landingHubId
      landingHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      totalDistanceInMeters
      durationInSeconds
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
    }
    vin
    actualStartTime
    actualArrivalTime
    plannedArrivalTime
    state {
      value
      updatedAt
    }
    progressDetails {
      updatedAt
      eta
      traveledDistanceInMeters
      remainingDistanceInMeters
    }
    events {
      type
      createdAt
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdatedMissionMutationVariables,
  APITypes.UpdatedMissionMutation
>;
export const cancelMission = /* GraphQL */ `mutation CancelMission($input: CancelMissionInput!) {
  cancelMission(input: $input) {
    id
    name
    description
    createdAt
    updatedAt
    route {
      predefinedRouteId
      externalRouteId
      launchHubId
      launchHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      landingHubId
      landingHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      totalDistanceInMeters
      durationInSeconds
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
    }
    vin
    actualStartTime
    actualArrivalTime
    plannedArrivalTime
    state {
      value
      updatedAt
    }
    progressDetails {
      updatedAt
      eta
      traveledDistanceInMeters
      remainingDistanceInMeters
    }
    events {
      type
      createdAt
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.CancelMissionMutationVariables,
  APITypes.CancelMissionMutation
>;
export const resetMission = /* GraphQL */ `mutation ResetMission($input: ResetMissionInput!) {
  resetMission(input: $input) {
    id
    name
    description
    createdAt
    updatedAt
    route {
      predefinedRouteId
      externalRouteId
      launchHubId
      launchHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      landingHubId
      landingHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      totalDistanceInMeters
      durationInSeconds
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
    }
    vin
    actualStartTime
    actualArrivalTime
    plannedArrivalTime
    state {
      value
      updatedAt
    }
    progressDetails {
      updatedAt
      eta
      traveledDistanceInMeters
      remainingDistanceInMeters
    }
    events {
      type
      createdAt
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.ResetMissionMutationVariables,
  APITypes.ResetMissionMutation
>;
export const archiveMission = /* GraphQL */ `mutation ArchiveMission($input: ArchiveMissionInput!) {
  archiveMission(input: $input) {
    id
    name
    description
    createdAt
    updatedAt
    route {
      predefinedRouteId
      externalRouteId
      launchHubId
      launchHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      landingHubId
      landingHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      totalDistanceInMeters
      durationInSeconds
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
    }
    vin
    actualStartTime
    actualArrivalTime
    plannedArrivalTime
    state {
      value
      updatedAt
    }
    progressDetails {
      updatedAt
      eta
      traveledDistanceInMeters
      remainingDistanceInMeters
    }
    events {
      type
      createdAt
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.ArchiveMissionMutationVariables,
  APITypes.ArchiveMissionMutation
>;
export const createHub = /* GraphQL */ `mutation CreateHub($input: CreateHubInput!) {
  createHub(input: $input) {
    id
    name
    position {
      latitude
      longitude
      altitude
      heading
    }
    active
    address
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateHubMutationVariables,
  APITypes.CreateHubMutation
>;
export const updateHub = /* GraphQL */ `mutation UpdateHub($input: UpdateHubInput!) {
  updateHub(input: $input) {
    id
    name
    position {
      latitude
      longitude
      altitude
      heading
    }
    active
    address
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateHubMutationVariables,
  APITypes.UpdateHubMutation
>;
export const updatedVehicle = /* GraphQL */ `mutation UpdatedVehicle($input: UpdatedVehicleInput) {
  updatedVehicle(input: $input) {
    vin
    externalId
    name
    licensePlate
    customerId
    speedInKmh
    weightInKg
    drivingState
    owners {
      id
      fullName
      position
      phoneNumber
      email
    }
    adsState
    status {
      type
      reason
      description
    }
    position {
      latitude
      longitude
      altitude
      heading
    }
    commandInfo {
      command
      executionState
      lastUpdatedAt
      errorMessage
    }
    alerts {
      id
      severity
      description
      category
      sentAt
    }
    latestVehicleUpdate
    createdAt
    updatedAt
    activeMissionId
    mrmState {
      updatedAt
      mrmStateValue
    }
    cameras {
      id
      name
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdatedVehicleMutationVariables,
  APITypes.UpdatedVehicleMutation
>;
export const updatedRoadSegmentStatus = /* GraphQL */ `mutation UpdatedRoadSegmentStatus($input: UpdatedRoadSegmentStatusInput) {
  updatedRoadSegmentStatus(input: $input) {
    id
    updatedAt
    createdAt
    reportedFromSourceAt
    source
    segment {
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
      from
      to
      direction
    }
    restrictionStart
    restrictionEnd
    restrictionCauses
  }
}
` as GeneratedMutation<
  APITypes.UpdatedRoadSegmentStatusMutationVariables,
  APITypes.UpdatedRoadSegmentStatusMutation
>;
export const continueStream = /* GraphQL */ `mutation ContinueStream($input: ContinueStreamInput!) {
  continueStream(input: $input) {
    vin
    cameraID
  }
}
` as GeneratedMutation<
  APITypes.ContinueStreamMutationVariables,
  APITypes.ContinueStreamMutation
>;
export const updatedVideoStreamingError = /* GraphQL */ `mutation UpdatedVideoStreamingError($input: UpdatedVideoStreamingErrorInput!) {
  updatedVideoStreamingError(input: $input) {
    vin
    errorCode
    cameraID
    errorMessage
  }
}
` as GeneratedMutation<
  APITypes.UpdatedVideoStreamingErrorMutationVariables,
  APITypes.UpdatedVideoStreamingErrorMutation
>;
