import { useMemo } from 'react'
import { Command, CommandInfo, ExecutionState } from 'API'

const useMRMProcess = (commandInfo?: CommandInfo) => {
  const { isMRM, isPerforming, isCompleted, isRelease } = useMemo(
    () => ({
      isMRM: commandInfo?.command === Command.MRM,
      isRelease: commandInfo?.command === Command.RELEASE,
      isPerforming: commandInfo?.executionState === ExecutionState.PERFORMING,
      isCompleted: commandInfo?.executionState === ExecutionState.REACHED
    }),
    [commandInfo]
  )

  const isLoading = isPerforming
  const isMRC = isCompleted && isMRM
  const isReleasing = isPerforming && isRelease

  if (!commandInfo || !commandInfo.command || !commandInfo.executionState) {
    return {
      isMRM: false,
      isPerforming: false,
      isCompleted: false,
      isRelease: false,
      isLoading: false,
      isMRC: false,
      isReleasing: false
    }
  }

  return {
    isMRM,
    isPerforming,
    isCompleted,
    isRelease,
    isLoading,
    isMRC,
    isReleasing
  }
}

export default useMRMProcess
