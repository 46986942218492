/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdateHub = /* GraphQL */ `subscription OnUpdateHub {
  onUpdateHub {
    id
    name
    position {
      latitude
      longitude
      altitude
      heading
    }
    active
    address
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHubSubscriptionVariables,
  APITypes.OnUpdateHubSubscription
>;
export const onUpdateMission = /* GraphQL */ `subscription OnUpdateMission($id: ID!) {
  onUpdateMission(id: $id) {
    id
    name
    description
    createdAt
    updatedAt
    route {
      predefinedRouteId
      externalRouteId
      launchHubId
      launchHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      landingHubId
      landingHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      totalDistanceInMeters
      durationInSeconds
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
    }
    vin
    actualStartTime
    actualArrivalTime
    plannedArrivalTime
    state {
      value
      updatedAt
    }
    progressDetails {
      updatedAt
      eta
      traveledDistanceInMeters
      remainingDistanceInMeters
    }
    events {
      type
      createdAt
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMissionSubscriptionVariables,
  APITypes.OnUpdateMissionSubscription
>;
export const onUpdateVehicle = /* GraphQL */ `subscription OnUpdateVehicle {
  onUpdateVehicle {
    vin
    externalId
    name
    licensePlate
    customerId
    speedInKmh
    weightInKg
    drivingState
    owners {
      id
      fullName
      position
      phoneNumber
      email
    }
    adsState
    status {
      type
      reason
      description
    }
    position {
      latitude
      longitude
      altitude
      heading
    }
    commandInfo {
      command
      executionState
      lastUpdatedAt
      errorMessage
    }
    alerts {
      id
      severity
      description
      category
      sentAt
    }
    latestVehicleUpdate
    createdAt
    updatedAt
    activeMissionId
    mrmState {
      updatedAt
      mrmStateValue
    }
    cameras {
      id
      name
    }
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVehicleSubscriptionVariables,
  APITypes.OnUpdateVehicleSubscription
>;
export const onUpdateRoadSegmentStatus = /* GraphQL */ `subscription OnUpdateRoadSegmentStatus {
  onUpdateRoadSegmentStatus {
    id
    updatedAt
    createdAt
    reportedFromSourceAt
    source
    segment {
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
      from
      to
      direction
    }
    restrictionStart
    restrictionEnd
    restrictionCauses
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRoadSegmentStatusSubscriptionVariables,
  APITypes.OnUpdateRoadSegmentStatusSubscription
>;
export const onUpdateVideoStreamingError = /* GraphQL */ `subscription OnUpdateVideoStreamingError($vin: String!, $cameraID: String) {
  onUpdateVideoStreamingError(vin: $vin, cameraID: $cameraID) {
    vin
    errorCode
    cameraID
    errorMessage
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVideoStreamingErrorSubscriptionVariables,
  APITypes.OnUpdateVideoStreamingErrorSubscription
>;
