export const ROUTES = {
  DEFAULT: '/',
  VEHICLE: 'vehicle',
  VEHICLE_BY_VIN: 'vehicle/:vin?',
  VEHICLE_INFO: 'info',
  VEHICLE_MISSION: 'mission',
  EXCEPTIONS: 'exceptions',
} as const

export type Route = (typeof ROUTES)[keyof typeof ROUTES]
